import React from 'react';
import {
  ActivityTitle,
  ActivitySubtitle,
  ActivityContainer,
  ActivityText,
  EventContent,
  ActivitiesDefaultContent
} from './styles';

const getDomainesOrDisciplinesById = (id, terms) => {
  const domaines = terms.filter((term) => term.evenements.includes(id));
  return domaines.map((domain) => domain.terme);
};

const PFEQ = (props) => {
  const { event, terms } = props;
  const { activites } = event;

  const eventId = event.EventId;
  
  const eventDomaines = getDomainesOrDisciplinesById(eventId, terms?.domaines);
  const eventDisciplines = getDomainesOrDisciplinesById(eventId, terms?.disciplines);


  return activites && activites?.length > 0 ? (
    <>
      <ActivityContainer>
        <ActivityTitle>APPRENTISSAGES</ActivityTitle>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '50%' }}>
            <ActivitySubtitle elementTheme='h4'>{eventDisciplines?.length === 1 ? 'Domaine' : 'Domaines'}</ActivitySubtitle>
            {eventDomaines?.map((domaine, index) => {
              return (
                <div key={`${domaine}--${index}`}>
                  <ActivityText>
                    <p>{domaine}</p>
                  </ActivityText>
                </div>
              );
            })}
          </div>
          <div style={{ width: '50%' }}>
            <ActivitySubtitle elementTheme='h4'>{eventDisciplines?.length === 1 ? 'Discipline' : 'Disciplines'}</ActivitySubtitle>
            {eventDisciplines?.map((discipline, index) => {
              return (
                <div key={`${discipline}--${index}`}>
                  <ActivityText>
                    <p>{discipline}</p>
                  </ActivityText>
                </div>
              );
            })}
          </div>
        </div>
      </ActivityContainer>
      <ActivityContainer>
        <ActivityTitle style={{ marginTop: '0' }}>Activités pédagogiques</ActivityTitle>
        {activites?.map((activity) => {
          return (
            <div key={activity.IdActCompetence}>
              <ActivityText>
                <p>{activity.LibelleCompetence}</p>
                {activity?.UrlActivite && (
                <a target='_blank' href={activity.UrlActivite}>
                  {activity.TitreActivite}
                </a>
              )}
              </ActivityText>
            </div>
          );
        })}
      </ActivityContainer>
    </>
  ) : (
    <EventContent>
      <ActivitiesDefaultContent>
        <p>
          Vous avez une idée d'activité éducative portant sur cet évènement?
          Communiquez avec nous et aidez-nous à en révéler le potentiel
          pédagogique!
        </p>
        <a
          href='https://education.banq.qc.ca/nous-joindre/'
          className='full-size-img__description-link'
        >
          Communiquez avec nous
        </a>
      </ActivitiesDefaultContent>
    </EventContent>
  );
};

export default PFEQ;
