import styled, { css } from 'styled-components';
import Typography from 'components/UI/Typography';
import { Link } from 'gatsby';

const containWidth = css`
  max-width: 1210px;
  padding: 0 40px;
  margin: 0 auto;
`;

export const EventContainer = styled.section`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  min-height: 100vh;

  .breadcrumb__content {

    .Typography.caption {
      transition: color 0.3s ease;

      :hover {
        color: ${(props) => props.theme.colors.secondary};
      }
    }
  }

  .tabs__container,
  .header__container {
    background: ${(props) => props.theme.colors.blue};
  }

  .Typography.h1 {
    font-weight: 300;
  }

  .Typography.h1,
  .Typography.h4,
  .Typography.caption {
    color: ${(props) => props.theme.colors.primary};
  }

  .Typography,
  .tab > .Typography {
    color: ${(props) => props.theme.colors.primary};
    transition: color 0.3s ease;
  }

  .tab > .Typography {
    :hover {
      color: ${(props) => props.theme.colors.secondary};
    }
  }

  .is-active {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export const EventContent = styled.div`
  padding: 75px 0 0;
  text-align: center;
`;

export const EventContentImage = styled.img`
  width: 100%;
`;

export const EventContentCredit = styled.div`
  margin-top: 30px;
  font-style: italic;
  padding: 0 30px;
`;

export const EventContainerImage = styled.div`
  padding: 40px 40px 30px 40px;
  background: ${(props) => props.theme.colors.light_brown};
  margin-bottom: 40px;
  position: relative;

  > a {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;

export const MainEventContent = styled.div`
  ${containWidth};
  width: 100%;

  .main-body-animate-enter {
    opacity: 0;
  }
  .main-body-animate-exit {
    opacity: 1;
  }
  .main-body-animate-enter-active {
    opacity: 1;
  }
  .main-body-animate-exit-active {
    opacity: 0;
  }
  .main-body-animate-enter-active,
  .main-body-animate-exit-active {
    transition: 500ms ease;
    transition-property: opacity;
  }
`;

export const ExternalButton = styled.a`
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: unset;
  color: ${(props) => props.theme.colors.primary};
  padding: 12px 18px;
  background: ${(props) => props.theme.colors.blue};
  margin: 35px auto 115px;
  text-decoration: none;
  display: inline-block;
  font-weight: 500;

  svg {
    margin-left: 12px;
  }
`;

// Pagination

export const PaginationBackground = styled.div`
  background: ${(props) => props.theme.colors.blue};
  padding: 45px 0;
`;

export const PaginationContainer = styled.div`
  display: flex;
  ${containWidth};
`;

export const PaginationContent = styled(Link)`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
`;

export const PaginationDate = styled(Typography)`
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const PaginationTitle = styled(Typography)`
  color: ${(props) => props.theme.colors.primary} !important;
  font-weight: 500;
`;

export const PaginationImage = styled.img`
  max-height: 125px;
  width: auto;
`;

export const ActivitiesDefaultContent = styled.div`
  max-width: 740px;
  margin: 0 auto;

  & p {
    margin-bottom: 30px;
    font-size: 20px;
  }
`;

export const ActivityTitle = styled(Typography)`
  letter-spacing: 2px !important;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  margin-top: 75px;
`;

export const ActivitySubtitle = styled(Typography)`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.purple} !important;
  letter-spacing: 2px !important;
  margin-bottom: 25px;
`;

export const ActivityContainer = styled.div`
  padding-top: 0;
  padding-bottom: 75px;
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
`

export const ActivityText = styled.div`
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(126, 139, 195, 0.5);
  display: flex;
  justify-content: space-between;
  padding-right: 15px;

  a {
    color: ${props => props.theme.colors.purple};
    text-decoration: underline;
  }
`